



























































































































































































































































































































.review-admin-contain{
  margin-top: -10px;
  margin-left:-24.5px;
  min-height: calc(100vh - 72px);
  margin-bottom: 0;
}

.gate-left{
  background: #284373;
  color:#fff;
}
.site-list {
  tr {
    border-bottom: 3px solid #284373;
  }
  li {
    background: #fff;
    border-radius: 8px;
    padding: 10px;
    margin-bottom: 10px;
    color: #222;

    img {
      max-width: 100px;
      height: auto;
    }
  }
}
.handle-icon{
  cursor: move;
}
.eyebtn{
  margin-top: -5px;
}
